import { useEffect, useState, useContext } from "react";
import {
  Bookmark,
  BookmarkBorderOutlined,
  Favorite,
  FavoriteBorderOutlined,
  Twitter,
} from "@mui/icons-material";
import StockButton from "./StockButton";
import AddButton from "./AddButton";
import { db } from "../../config/Firebase";
import firebase from "firebase";
import { ExploreStockNewsContext } from "../../services/exploreStocksNews/exploreStocksNews.context";


function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}



const News = ({ publisher_name, title, author, published_utc, article_url, tickers, image_url, description, id, history, likesRandom }) => {
  const date = new Date(published_utc);

  const [num, setNum] = useState(0);
  const [tickersData, setTickersData] = useState([]);
  const { height, width } = useWindowDimensions();
  const [liked, setLiked] = useState(false);
  const [bookmarked, setBookmarked] = useState(false);
  const [hover, setHover] = useState(false);
  const [likes, setLikes] = useState(0)
  const [likeHover, setLikeHover] = useState(false);
  const [bookmarkHover, setBookmarkHover] = useState(false);
  const [uid, setUid] = useState("");
  const { fetchTickerPercentage } = useContext(ExploreStockNewsContext);

  useEffect(() => {
    async function func() {  
      firebase.auth().onAuthStateChanged(async function (user) {
        if (user) {
          setUid(user.uid);
          await getBookmarked();
        } 
      });
      await getLiked();
      await getTickers();
    }
    func();
  }, []);

  const getLiked = async () => {
    
    var likesCount = await firebase.firestore().collection('likes').where('id', '==', id).get();
    setLikes(likesCount.size)

    if(uid){
      var ref = db.collection("likes").doc(`${uid}-${id}`);
      var data = await ref.get();
      if (data.exists) {
        setLiked(true);
      }
    }
  };

  const getBookmarked = async () => {
    if(uid){  
      var ref = db.collection("bookmarks").doc(`${uid}-${id}`);
      var data = await ref.get();
  
      if (data.exists) {
        setBookmarked(true);
      }
    }
  };

  const getTickers = async () => {
    var tickersArray = [];
    var screenNum = 2;

    if (width >= 1024) {
      screenNum = 2;
    } else {
      screenNum = 1;
    }
    for (let i = 0; i < Math.min(screenNum, tickers.length); i++) {
      const per = await fetchTickerPercentage(tickers[i]);
      console.log("fetchTickerPercentage per", per)
      tickersArray.push({
        ticker: tickers[i],
        percentage: per,
      });
    }
    setNum((tickers.length > screenNum) ? (tickers.length - screenNum) : 0);
    setTickersData(tickersArray);
  }

  const setTickers = async () => {
    var screenNum = 2;

    if (width >= 1024) {
      screenNum = 2;
    } else {
      screenNum = 1;
    }

    const arr = [];

    for (var ticker = 0; ticker < Math.min(screenNum, tickers.length); ticker++) {
      const functionRef = firebase.app().functions('us-central1').httpsCallable('stockSnapshot');
      var tickerData = ""

      try {
        tickerData = await functionRef({
          stockName: tickers[ticker],
        });
        tickerData['data'] = JSON.parse(tickerData.data);

        if (tickerData.day.o == 0) {
          throw "error";
        }

        console.log("firebase", tickerData, tickers[ticker]);

        arr.push({
          ticker: tickers[ticker],
          percent: (tickerData.data.ticker.todaysChangePerc).toFixed(2),
        });
      }
      catch (error) {
        console.log("firebase error snap", error, tickers[ticker]);

        const functionRef = firebase.app().functions('us-central1').httpsCallable('lastDayClose');
        var lastDayClose = ""

        lastDayClose = await functionRef({
          stockName: tickers[ticker],
        });
        lastDayClose['data'] = JSON.parse(lastDayClose.data);

        var data = {
          Close: (lastDayClose.data.results[0].c).toFixed(2),
        }

        // get open-close data for date before lastDayClose.data.results[0].t\
        // var days_behind = 1;
        var dayBefore = ""
        const functionRef2 = firebase.app().functions('us-central1').httpsCallable('stockOpenClose');
        var dayBefore = ""
        dayBefore = await functionRef2({
          stockName: tickers[ticker],
          time: lastDayClose.data.results[0].t
        });
        dayBefore['data'] = JSON.parse(dayBefore.data);



        data['Prev Close'] = (dayBefore.data.close).toFixed(2);
        var percent = (((data.Close - data['Prev Close']) / data['Prev Close']) * 100).toFixed(2)

        arr.push({
          ticker: tickers[ticker],
          percent: percent,
        });
      }
    }

    setNum((tickers.length > screenNum) ? (tickers.length - screenNum) : 0);
    setTickersData(arr);
  };

  const likeHandle = async () => {
    var ref = db.collection("posts").doc(id);
    var doc = await ref.get();

    if (!doc.exists) {
      ref.set({
        id: id,
        article_url: article_url || "",
        author: author || "",
        image_url: image_url || "",
        tickers: tickers || "",
        description: description || "",
        publisher: publisher_name || "",
        title: title || "",
        published_utc: published_utc || "",
      });
    }
    if (liked) {
      db.collection("likes").doc(`${uid}-${id}`).delete();
      setLikes(likes - 1)
      setLiked(false);
    } else {
      console.log(id);
      db.collection("likes").doc(`${uid}-${id}`).set({
        user: uid,
        id: id,
      });
      setLikes(likes + 1)
      setLiked(true);
    }
  };

  const bookmarkHandle = async (uidIn) => {
    console.log("UID");
    console.log(uidIn);
    var ref = db.collection("posts").doc(id);
    var doc = await ref.get();

    if (!doc.exists) {
      ref.set({
        id: id,
        article_url: article_url,
        author: author,
        image_url: image_url,
        tickers: tickers,
        description: description,
        publisher: publisher_name,
        title: title,
        published_utc: published_utc,
      });
    }
    if (bookmarked) {
      db.collection("bookmarks").doc(`${uidIn}-${id}`).delete();
      setBookmarked(false);
    } else {
      db.collection("bookmarks").doc(`${uidIn}-${id}`).set({
        user: uid,
        id: id,
      });
      setBookmarked(true);
    }
  };

  return (
    <div
      className="text-left relative justify-between"

    >
      <div className="mb-4">
        <div className="flex justify-between">
          <div className="flex gap-1 mb-2 pt-1">
            <p className="text-xs text-gray-700">
              {date.toString().split(" ")[1]}{" "}
            </p>
            <p className="text-xs text-gray-700">
              {date.toString().split(" ")[2]},{" "}
            </p>
            <p className="text-xs text-gray-700">
              {date.toString().split(" ")[3]}{" "}
            </p>
          </div>
          <div className="mb-4 space-x-2 flex">
            {tickersData.length ? tickersData.map((ticker3) => {
              return (


                <StockButton
                  stock={ticker3.ticker}
                  percent={parseFloat(ticker3.percentage)}
                  key={ticker3.ticker}
                  history={history}
                />


              );
            })
              :
              [...Array(2)].map((key, i) => {
                return (

                  <div className="rounded-md w-16 h-6 mx-auto">
                    <div className="animate-pulse w-full h-full space-x-4">
                      <div className="bg-gray-300 w-full h-full mb-2 rounded"></div>
                    </div>
                  </div>
                )
              })
            }
            {num === 0 ? <></> : <AddButton num={num} />}
          </div>
        </div>
        <div className="cursor-pointer" onClick={() => {
          window.open(article_url);
        }}>
          <div className="relative h-0 pb-2/3 mb-2 w-full h-full">
            <img
              className="absolute inset-0 object-cover rounded-lg object-contain w-full h-full"
              src={image_url}
              alt={title}
            />
          </div>

          <div>
            <h1 className="text-lg md:text-xl lg:text-2xl mb-2 font-bold font-sourceSansPro">
              {title}
            </h1>
            <div>
              <p className="text-sm text-gray-500 line-clamp-3">
                ({publisher_name}) -- {description}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex space-x-4 cursor-pointer">


        <div
          className="cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            likeHandle();
          }}
        >
          <div>
            {liked ? <Favorite /> : <FavoriteBorderOutlined sx={{ "&:hover": { color: "gray" } }} />}
            <span className="text-xs ml-1">{likes + likesRandom}</span>
          </div>
        </div>

        <div
          onClick={(e) => {
            e.stopPropagation();
            bookmarkHandle(uid);
          }}
        >
          {bookmarked ? <Bookmark /> : <BookmarkBorderOutlined sx={{ "&:hover": { color: "gray" } }}/>}
        </div>

        <div
          className="cursor-pointer"
          onMouseOver={() => {
            setHover(true);
          }}
          onClick={(e) => {
            window.open(`https://twitter.com/intent/tweet?text=${article_url}`);
            e.stopPropagation();
          }}
        >
          <Twitter style={{ fill: "#1DA1F2" }} />
        </div>
      </div>
    </div>
  );
};

export default News;
