import React, { useEffect } from "react";
import GoogleAd from "./GoogleAd";
import News from "./News";
import NewsLoading from "./NewsLoading";

const NewsGrid = ({ data, history, loaded = false, loadAds = false }) => {
  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-4">
        {(loaded ? data : [...Array(9)]).map((news, key) => {
          return (
            loaded ?
              (
                <>
                  {/* {
                    ((key+1) % 3 === 0 && loadAds===true) ?
                     <GoogleAd /> 
                     : <> </>
                  } */}
                  <News
                    published_utc={news.published_utc}
                    publisher_name={news.publisher.name}
                    title={news.title}
                    author={news.author}
                    tickers={news.tickers}
                    description={news.description}
                    image_url={news.image_url}
                    article_url={news.article_url}
                    key={news.id}
                    id={news.id}
                    history={history}
                    likesRandom={Math.floor(Math.random()*400+50)}
                  />
                </>
              )
              :
              <NewsLoading keys={news} />
          );
        })}
      </div>
    </div>
  );
};

export default NewsGrid;
